import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Divider,
  FormLabel,
  FormControl,
  Link,
  TextField,
  Typography,
  Stack,
  Card as MuiCard,
} from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { GitHub as GitHubIcon } from '@mui/icons-material';
import logo from '../../assets/BP-logo-X.png';
import { auth } from '../../firebase';
import {
  createUserWithEmailAndPassword,
  signInWithPopup,
  GithubAuthProvider,
  fetchSignInMethodsForEmail,
  updateProfile,
} from 'firebase/auth';

// Styled Card component
const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: 'auto',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '450px',
  },
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
}));

// Styled Stack component for layout
const SignUpContainer = styled(Stack)(({ theme }) => ({
  minHeight: '100vh',
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

export default function SignUp() {
  const [nameError, setNameError] = React.useState(false);
  const [nameErrorMessage, setNameErrorMessage] = React.useState('');
  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');
  const [signupError, setSignupError] = React.useState('');

  const navigate = useNavigate();

  const validateInputs = () => {
    const email = document.getElementById('email');
    const password = document.getElementById('password');
    const name = document.getElementById('name');

    let isValid = true;

    if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
      setEmailError(true);
      setEmailErrorMessage('Please enter a valid email address.');
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage('');
    }

    if (!password.value || password.value.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage('Password must be at least 6 characters long.');
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage('');
    }

    if (!name.value || name.value.length < 1) {
      setNameError(true);
      setNameErrorMessage('Name is required.');
      isValid = false;
    } else {
      setNameError(false);
      setNameErrorMessage('');
    }

    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateInputs()) {
      return;
    }

    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');
    const name = data.get('name');

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      await updateProfile(user, {
        displayName: name,
      });
      console.log('User signed up:', user);
      navigate('/'); // Redirect to home page
    } catch (error) {
      console.error('Error signing up:', error);
      if (error.code === 'auth/email-already-in-use') {
        try {
          const methods = await fetchSignInMethodsForEmail(auth, email);
          if (methods.length > 0) {
            let existingProvider = methods[0];
            let providerName = 'another method';
            if (existingProvider === 'github.com') providerName = 'GitHub';
            else if (existingProvider === 'password') providerName = 'email and password';
            setSignupError(
              `An account with this email already exists. Please sign in with ${providerName}.`
            );
          } else {
            setSignupError('An account with this email already exists.');
          }
        } catch (fetchError) {
          console.error('Error fetching sign-in methods:', fetchError);
          setSignupError('An error occurred. Please try again later.');
        }
      } else {
        setSignupError(error.message);
      }
    }
  };

  const signInWithGitHub = async () => {
    const provider = new GithubAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      console.log('User signed in with GitHub:', user);
      navigate('/'); // Redirect to home page
    } catch (error) {
      console.error('Error signing in with GitHub:', error);
      if (error.code === 'auth/account-exists-with-different-credential') {
        const email = error.customData.email;
        try {
          const methods = await fetchSignInMethodsForEmail(auth, email);
          if (methods.length > 0) {
            let existingProvider = methods[0];
            let providerName = 'another method';
            if (existingProvider === 'github.com') providerName = 'GitHub';
            else if (existingProvider === 'password') providerName = 'email and password';
            setSignupError(
              `An account with this email already exists. Please sign in with ${providerName}.`
            );
          } else {
            setSignupError('An account with this email already exists.');
          }
        } catch (fetchError) {
          console.error('Error fetching sign-in methods:', fetchError);
          setSignupError('An error occurred. Please try again later.');
        }
      } else {
        setSignupError(error.message);
      }
    }
  };

  return (
    <SignUpContainer direction="column" justifyContent="center">
      <Card variant="outlined">
        {/* Logo Image */}
        <Box
          component="img"
          src={logo}
          alt="The Brilliant Portal Logo"
          sx={{
            height: { xs: 30, sm: 40 }, // Responsive height
            width: 'auto',
            alignSelf: 'center',
            transition: 'transform 0.2s, opacity 0.2s',
            '&:hover': {
              transform: 'scale(1.05)',
              opacity: 0.8,
            },
          }}
        />

        <Typography
          component="h1"
          variant="h4"
          sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)', textAlign: 'center' }}
        >
          Sign up
        </Typography>

        {signupError && (
          <Typography color="error" sx={{ textAlign: 'center' }}>
            {signupError}
          </Typography>
        )}

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
          noValidate
        >
          <FormControl>
            <FormLabel htmlFor="name">Full name</FormLabel>
            <TextField
              autoComplete="name"
              name="name"
              required
              fullWidth
              id="name"
              placeholder="Jon Snow"
              error={nameError}
              helperText={nameErrorMessage}
              color={nameError ? 'error' : 'primary'}
            />
          </FormControl>

          <FormControl>
            <FormLabel htmlFor="email">Email</FormLabel>
            <TextField
              required
              fullWidth
              id="email"
              placeholder="your@email.com"
              name="email"
              autoComplete="email"
              variant="outlined"
              error={emailError}
              helperText={emailErrorMessage}
              color={emailError ? 'error' : 'primary'}
            />
          </FormControl>

          <FormControl>
            <FormLabel htmlFor="password">Password</FormLabel>
            <TextField
              required
              fullWidth
              name="password"
              placeholder="••••••"
              type="password"
              id="password"
              autoComplete="new-password"
              variant="outlined"
              error={passwordError}
              helperText={passwordErrorMessage}
              color={passwordError ? 'error' : 'primary'}
            />
          </FormControl>

          <FormControlLabel
            control={<Checkbox value="allowExtraEmails" color="primary" />}
            label="I want to receive updates via email."
          />

          <Button type="submit" fullWidth variant="contained">
            Sign up
          </Button>

          <Typography sx={{ textAlign: 'center' }}>
            Already have an account?{' '}
            <Link
              component={RouterLink}
              to="/signin"
              variant="body2"
              sx={{ alignSelf: 'center' }}
            >
              Sign in
            </Link>
          </Typography>
        </Box>

        <Divider>
          <Typography sx={{ color: 'text.secondary' }}>or</Typography>
        </Divider>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Button
            fullWidth
            variant="outlined"
            onClick={signInWithGitHub}
            startIcon={<GitHubIcon />}
          >
            Sign up with GitHub
          </Button>
        </Box>
      </Card>
    </SignUpContainer>
  );
}
