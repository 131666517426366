import React, { createContext, useState, useEffect } from 'react';
import { auth } from '../firebase'; // Ensure correct path
import { onAuthStateChanged } from 'firebase/auth';

export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true); // Add a loading state

  useEffect(() => {
    // Listen for auth state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false); // Set loading to false after the user state is set
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // You can add more functions here (e.g., signOut)

  const value = {
    currentUser,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}